import { Badge, Box, ButtonBase, Divider, styled, useMediaQuery, Theme } from '@mui/material';
import AppAvatar from 'components/avatars/AppAvatar';
import FlexBox from 'components/flexbox/FlexBox';
import { H6, Small, Tiny } from 'components/Typography';
import useAuth from 'hooks/useAuth';
import { FC, Fragment, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import PopoverLayout from './PopoverLayout';
import CONSTS from "../../../utils/consts";

// styled components
const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  padding: 5,
  marginLeft: 4,
  borderRadius: 30,
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': { backgroundColor: theme.palette.action.hover },
}));

const StyledSmall = styled(Small)(({ theme }) => ({
  display: 'block',
  cursor: 'pointer',
  padding: '5px 1rem',
  '&:hover': { backgroundColor: theme.palette.action.hover },
}));

const StyledSmallBold = styled(Small)(({ theme }) => ({
  display: 'block',
  cursor: 'pointer',
  fontWeight: 700,
  padding: '5px 1rem',
  '&:hover': { backgroundColor: theme.palette.action.hover },
}));

const ProfilePopover: FC = () => {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [open, setOpen] = useState(false);
  const [userLogin, setUserLogin] = useState(false);
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const getUser = ()=>{
    const data:any = window.localStorage.getItem(CONSTS.USER);
    return JSON.parse(data)
  }
  const user = getUser();

  const handleMenuItem = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  const handleLogout = () => {
    window.localStorage.setItem(CONSTS.USER, '')
    navigate('/login');
  };
  
  const buyCredits = () => {
    navigate('/buy-credits');
  }

  const userOnline = ()=>{
    const style = {
      alignItems: 'center',
      '& .MuiBadge-badge': {
        width: 11,
        height: 11,
        right: '4%',
        borderRadius: '50%',
        border: '2px solid #fff',
        backgroundColor: 'success.main',
      },
    }
    return userLogin ? style : {};
  }

  return (
    <Fragment>
      <StyledButtonBase disableRipple ref={anchorRef} onClick={() => setOpen(true)}>
        <Badge
          overlap="circular"
          variant="dot"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={userOnline}
        >
          <AppAvatar
            src={user?.avatar || '/static/avatar/001-man.svg'}
            sx={{ width: 28, height: 28 }}
          />
        </Badge>
      </StyledButtonBase>

      <PopoverLayout
        hiddenViewButton
        maxWidth={230}
        minWidth={200}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        title={
          <FlexBox alignItems="center" gap={1}>
            <AppAvatar
              src={user?.avatar || '/static/avatar/001-man.svg'}
              sx={{ width: 35, height: 35 }}
            />

            <Box style={{cursor:"pointer"}} onClick={buyCredits}>
              <H6>{user?.given_name} {user?.family_name}</H6>
              <Tiny display="block" fontWeight={500} color="text.disabled">
                0 Credits
              </Tiny>
            </Box>
          </FlexBox>
        }
      >
        <Box pt={1}>

          {/*<StyledSmallBold onClick={() => handleMenuItem('/')}>*/}
          {/*  Wishlists*/}
          {/*</StyledSmallBold>*/}

          <StyledSmallBold onClick={() => handleMenuItem('/create')}>
            Create Winday
          </StyledSmallBold>

          <Divider sx={{ my: 1 }} />

          {/*<StyledSmall onClick={() => handleMenuItem('/')}>*/}
          {/*  Refer a friend*/}
          {/*</StyledSmall>*/}

          <StyledSmall onClick={() => handleMenuItem('/account')}>
            My Account
          </StyledSmall>
          <StyledSmall
              onClick={() => handleMenuItem('/about')}
          >
            About
          </StyledSmall>

          <StyledSmall
              onClick={() => handleMenuItem('/privacy')}
          >
            Privacy
          </StyledSmall>

          <Divider sx={{ my: 1 }} />

          <StyledSmall
            onClick={() => {
              handleLogout();
              toast.error('You Logout Successfully');
            }}
          >
            Sign Out
          </StyledSmall>
        </Box>
      </PopoverLayout>
    </Fragment>
  );
};

export default ProfilePopover;
