import duotone from 'icons/duotone';

export const navigations = [
  { type: 'label', label: 'Home' },
  // {
  //   name: 'Recently Viewed',
  //   path: '/dashboard/project-management-v2',
  //   icon: duotone.DiagramProject,
  // },

  {
    name: 'All Windays',
    path: '/all-windays',
    icon: duotone.CommentsQuestionCheck,
  },

  { type: 'label', label: 'Create Winday' },
  {
    name: 'Create new',
    icon: duotone.UserProfile,
    path: '/create',
  },

  // { type: 'label', label: 'WINNERS' },
  //
  // { name: 'Recent Winners', icon: duotone.TodoList, path: '/dashboard/todo-list' },
  // { name: 'All Winners', icon: duotone.Calender, path: '/dashboard/calender' },
];
