import { FC, useEffect, useState } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import RTL from "components/RTL";
import useSettings from "hooks/useSettings";
import { createCustomTheme } from "./theme";
import "./i18n";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "./utils/axios";
import Router from "./Router";

const App: FC = () => {
  const [showLoader, setShowLoader] = useState(false);
  const { settings } = useSettings();

  axios.interceptors.request.use(
    function (config) {
      setShowLoader(true);
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      setShowLoader(false);
      return response;
    },
    function (error) {
      setShowLoader(false);
      return Promise.reject(error);
    }
  );

  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {showLoader && <LinearProgress />}
        <RTL>
          <CssBaseline />
          <Router />
        </RTL>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
