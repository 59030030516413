import { IRootStore } from '../RootStore/RootStore'

class CompetitionStore {
    // @ts-ignore
    root: IRootStore = null
    constructor(root: IRootStore) {
        this.root = root
    }
}

export default CompetitionStore
