import { Amplify, Auth } from "aws-amplify";

export interface SignUpUser {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  birthdate: string;
  username: string;
}

export interface ConfirmSignUpUser {
  userName: string;
  code: string;
}

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:8afedb78-9d9d-4b24-8ea0-168539fd5e6f",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_AEl77eRXk",
    userPoolWebClientId: "7apbnkh0bgvbn8dvdkh8croii3",
  },
});

export const signUp = async (userToSignUp: SignUpUser) => {
  try {
    const user = await Auth.signUp({
      username: userToSignUp.username,
      password: userToSignUp.password,
      attributes: {
        given_name: userToSignUp.firstName,
        family_name: userToSignUp.lastName,
        email: userToSignUp.email,
        phone_number: userToSignUp.phoneNumber,
        birthdate: userToSignUp.birthdate,
      },
      autoSignIn: {
        enabled: true,
      },
    });
    return user;
  } catch (error) {
    throw error;
  }
};

export const isUserSessionValid = async () => {
  try {
    const isUserSessionValid = (await Auth.currentSession()).getAccessToken();
    return isUserSessionValid;
  } catch (error) {
    throw error;
  }
};

export const signInWithEmail = async (userName: string, password: string) => {
  try {
    const user = await Auth.signIn(userName, password);
    return user;
  } catch (error) {
    throw error;
  }
};

export const restorePassword = async (email: string) => {
  try {
    const user = await Auth.forgotPassword(email);
    return user;
  } catch (error) {
    throw error;
  }
};
export const forgotPasswordSubmit = async (
  email: string,
  code: string,
  password: string
) => {
  try {
    const user = await Auth.forgotPasswordSubmit(email, code, password);
    return user;
  } catch (error) {
    throw error;
  }
};
async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

export const confirmSignUp = async (confirmSignUpUser: ConfirmSignUpUser) => {
  try {
    return await Auth.confirmSignUp(
      confirmSignUpUser.userName,
      confirmSignUpUser.code
    );
  } catch (error) {
    throw error;
  }
};
