import {FC, lazy, LazyExoticComponent, Suspense} from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import LayoutV3 from "layouts/layout-v3/DashboardLayout"
import LoadingScreen from "../components/LoadingScreen";
import ProtectedRoute from "../pages/ProtectedRoute/ProtectedRoute";
import Account from "../pages/accounts/account";
import About from "../pages/about";
import Privacy from "../pages/privacy";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) => {
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    );
};

const Home = Loadable(
    lazy(() => import("pages/dashboards/home"))
);

const Create = Loadable(
    lazy(() => import("pages/dashboards/create"))
);
const Login = Loadable(lazy(() => import("pages/authentication/login")));
const Error = Loadable(lazy(() => import("pages/404")));
const Register = Loadable(
    lazy(() => import("pages/authentication/register"))
);
const RegisterV2 = Loadable(
    lazy(() => import("pages/authentication/register-v2"))
);
const NewPassword = Loadable(
    lazy(() => import("pages/authentication/new-password"))
);
const ForgetPassword = Loadable(
    lazy(() => import("pages/authentication/forget-password"))
);
const TwoStepVerify = Loadable(
    lazy(() => import("pages/authentication/two-step-verification"))
);
const AllWindays = Loadable(
    lazy(() => import("pages/all-windays"))
);

const ProductDetails = Loadable(
    lazy(() => import("pages/ecommerce/product-details"))
);
const Complete = Loadable(
    lazy(() => import("pages/ecommerce/payment-complete"))
);

const Pricing = Loadable(
    lazy(() => import("pages/pricing"))
);

const Router: FC = () => {
  const { pathname } = useLocation()
  return (
    <Routes>
        <Route path="/login" element={<Login />} />
        {/*<Route path="/register" element={<Register />} />*/}
        <Route path="/closed-beta-register" element={<RegisterV2 />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/two-step-verification" element={<TwoStepVerify />} />
        <Route path="*" element={<Error />} />

        <Route element={<ProtectedRoute />}>
            <Route path="/" element={<LayoutV3><Home /></LayoutV3>} />
            <Route path="/create" element={<LayoutV3><Create /></LayoutV3>} />
            <Route path="/all-windays" element={<LayoutV3><AllWindays /></LayoutV3>} />
            <Route path="/competition/:competitionId" element={<LayoutV3><ProductDetails /></LayoutV3>} />
            <Route path="/complete" element={<LayoutV3><Complete /></LayoutV3>} />
            <Route path="/buy-credits" element={<LayoutV3><Pricing /></LayoutV3>} />
            <Route path="/account" element={<LayoutV3><Account /></LayoutV3>} />
            <Route path="/about" element={<LayoutV3><About /></LayoutV3>} />
            <Route path="/privacy" element={<LayoutV3><Privacy /></LayoutV3>} />
        </Route>
    </Routes>
  )
}

export default Router
