import { Theme } from "@mui/material";

export const THEMES = { LIGHT: "light", DARK: "dark" };

export const lightTheme = (theme: Theme) => theme.palette.mode === "light";

export const secondarySideBarWidth = 215;
export const secondarySideBarGap = 80;

// DNS
export const DNS_TYPE = {
    All: 0,
}

export const DNS_STATUS = {
    active: 1,
}

// ASSETS
export const ASSET_TYPE = {
    All: 0,
    Domain: 1,
    Subdomain: 2,
    Certificate: 3,
    IpAsset: 4,
    Bucket: 5,
}