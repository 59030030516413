import {useNavigate, useOutlet} from "react-router-dom";
import {isUserSessionValid} from "../../utils/cognito";
import {useEffect} from "react";

const ProtectedLayout = () => {
    const navigate: any = useNavigate();
    useEffect(() => {
        (async () => {
            try {
                await isUserSessionValid();
            } catch (e) {
                navigate("/login", { token: false });
            }
        })();
    }, []);
    const outlet = useOutlet();

    return (
        <div>
            {outlet}
        </div>
    );
};

export default ProtectedLayout