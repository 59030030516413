import CompetitionStore from "../CompetitionStore/CompetitionStore";

export interface IRootStore {
  competitionStore:CompetitionStore
}

class RootStore {
  competitionStore:CompetitionStore
  constructor() {
    this.competitionStore = new CompetitionStore(this)
  }
}

export default new RootStore()
